import { PAYMENT_URL } from '../Config/globals';
import apiService from './apiService';

const PaymentServices = {
  getLastUnpaidOrder: async () => {
    const { data } = await apiService.get('/payments/last-checkout/?edu=True');
    return data;
  },
  getPaymentDetail: async ({ orderId }) => {
    const { data } = await apiService.get(`/payments/detail/${orderId}`);
    return data;
  },
  getPaymentStatus: async ({ invoiceId }) => {
    const { data } = await apiService.get(`/payments/check-status/${invoiceId}`);
    return data;
  },
  getActiveFeatures: async () => {
    const { data } = await apiService.get('/features/active/:uid');
    return data;
  },
  renewalPayment: async ({ invoice, method = 'midtrans' }) => {
    const { data } = await apiService.post(`/payments/renewal-${method === 'midtrans' ? 'midtrans' : 'stripe'}/`, {
      invoice,
      success_url: `${PAYMENT_URL}/Success`,
      cancel_url: `${PAYMENT_URL}/Failed`,
    });
    return data;
  },
  cancelSubs: async ({ invoice }) => {
    try {
      const { status } = await apiService.post('/payments/cancel-stripe/', { invoice });
      return status;
    } catch (err) {
      console.log({ err }, 'cancelSubs');
      return null;
    }
  },
  cancelPlaystore: async ({ invoice }) => {
    try {
      const { status } = await apiService.post('/payments/cancel-playstore/', { invoice });
      return status;
    } catch (err) {
      console.log({ err }, 'cancel playstore');
      return null;
    }
  },
};

export default PaymentServices;
