/* eslint-disable react/react-in-jsx-scope */
import Head from 'next/head';

import { useMemo } from 'react';

function Seo(initialSeo = {}) {
    const {
        title, description, ogImage, twitterImage, image, meta, link,
    } = initialSeo;

    const defaultSeo = useMemo(() => ({
        title: 'Assemblr EDU Web | 3D & AR Premade Educational Topics',
        description: 'Use hundreds of Assemblr EDU premade educational topics of various subjects in 3D/AR experiences. Bring interactive learning materials with less preparation!',
        image: 'https://uploads-ssl.webflow.com/5df8a0c2f731a2a56ae02cf9/5facd407aaa0830c883e4890_opengraph-EDU-min.jpg',
        ogImage: 'https://uploads-ssl.webflow.com/5df8a0c2f731a2a56ae02cf9/5facd407aaa0830c883e4890_opengraph-EDU-min.jpg',
    }), [title, description, ogImage, twitterImage, image, meta, link]);

    const seo = useMemo(() => ({
        title: title || defaultSeo?.title,
        description: description || defaultSeo?.description,
        ogImage: ogImage || image || defaultSeo?.ogImage || defaultSeo?.image,
        twitterImage: twitterImage || image || defaultSeo?.twitterImage || defaultSeo?.image,
    }), [
        title,
        defaultSeo?.title,
        defaultSeo?.description,
        defaultSeo?.image,
        defaultSeo?.twitterImage,
        description,
        ogImage,
        image,
        twitterImage,
    ]);

    return (
      <Head>
        {/* <!-- Twitter --> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
        <meta name="twitter:card" content="player" key="twitter:card" />
        <meta name="twitter:title" content={`${seo.title}`} key="twitter:title" />
        <meta name="twitter:site" content="Assemblr App" key="twitter:site" />
        <meta name="twitter:player:width" content="480" key="twitter:player:width" />
        <meta name="twitter:player:height" content="480" key="twitter:player:height" />
        <meta
          name="twitter:image"
          content={seo?.twitterImage}
          key="twitter:image"
        />
        <meta
          name="twitter:description"
          content={seo.description}
          key="twitter:description"
        />

        {/* <!-- Primary Meta Tags --> */}
        <title>{seo.title}</title>
        <meta name="title" content={`${seo.title}`} key="title" />
        <meta
          name="description"
          content={seo.description}
          key="description"
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:title" content={`${seo.title}`} key="og:title" />
        <meta
          property="og:description"
          content={seo.description}
          key="og:description"
        />
        <meta property="og:image" content={seo?.ogImage} key="og:image" />
        <meta property="og:image:width" content="560" />
        <meta property="og:image:height" content="316" />

        {/* Iframely */}
        <meta property="iframely:title" content={`${seo.title}`} />
        <meta property="iframely:description" content={seo.description} />
        <meta property="iframely:image" content={seo?.ogImage} />

        {
                meta?.map((_meta) => (
                  <meta
                    key={_meta.name}
                    {..._meta}
                  />
                ))
            }
        {
                link?.map((_link) => (
                  <link
                    key={_link.rel}
                    {..._link}
                  />
                ))
            }
      </Head>
    );
}

export default Seo;
