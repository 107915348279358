const isClient = typeof window !== 'undefined';

const userAgent = {
  isMobile: {
    Android: () => isClient && window.navigator.userAgent.match(/Android/i),
    IOS: () => isClient && window.navigator.userAgent.match(/iPhone|iPad|iPod|ios/i),
    Assemblr: () => isClient && window.navigator.userAgent.toLowerCase().includes('assemblr'),
    any: () => isClient
    && (userAgent.isMobile.Android()
    || userAgent.isMobile.IOS()
     || userAgent.isMobile.Assemblr()),
  },
};

export default userAgent;
