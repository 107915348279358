/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import nookies from 'nookies';
import PaymentServices from '../../services/PaymentServices';
import Helpers from '../../Utils/Helpers';
import Store from '../../Config/Store';

export const notificationExludePaths = ['/Setting', '/auth/login'];

const initialState = {
  unpaidInvoice: null,
  unpaidInvoiceType: null,
  generalSequence: [],
  status: 'idle',
  currentGeneralNotification: null,
  delayBetweenNotification: 60 * 1000,
  delayUnpaidInvoiceNotification: 28800 * 1000,
  verifyEmailPopup: false,
  verifyEmailSent: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setUnpaidInvoice: (state, action) => {
      state.unpaidInvoice = action.payload;
    },
    setUnpaidInvoiceType: (state, action) => {
      if (!action.payload) return;
      state.unpaidInvoiceType = action.payload;
    },
    setVerifyEmailPopup: (state, { payload }) => {
      state.verifyEmailPopup = payload;
    },
    setVerifyEmailSent: (state) => {
      state.verifyEmailSent = true;
    },
    deleteUnpaidNotif: (state) => {
      state.generalSequence = state.generalSequence
        .filter((notification) => notification.type !== 'unpaid_invoice');
    },
    queueNotification: (state, action) => {
      const hasNotification = current(state).generalSequence
        .find((notification) => notification.key === action.payload.key);

      const isUnpaidExist = current(state).generalSequence
        .find((notification) => notification.type === 'unpaid_invoice');

      if ((!hasNotification && action.payload.type !== 'unpaid_invoice') || (action.payload.type === 'unpaid_invoice' && !isUnpaidExist)) {
        state.generalSequence.push({
          ...action.payload,
          hasSeen: false,
          priority: action.payload.priority || 0,
        });
      } else if (action.payload.type === 'unpaid_invoice' && isUnpaidExist) {
        const newState = state.generalSequence.map((notification) => {
          if (notification.type === 'unpaid_invoice') {
            return {
              ...action.payload,
              hasSeen: false,
              priority: action.payload.priority || 0,
            };
          }
          return notification;
        });
        return { ...state, generalSequence: newState };
      } else {
        state.generalSequence = state.generalSequence.map((notification) => {
          if (notification.key === action.payload.key) {
            return {
              ...notification,
              hasSeen: action.payload.hasSeen === undefined
                ? notification.hasSeen : action.payload.hasSeen,
            };
          }
          return notification;
        });
      }
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    resetNotification(state) {
      Object.assign(state, {
        ...initialState,
      });
    },
    setHasSeenNotification(state, action) {
      state.generalSequence = state.generalSequence.map((notification) => {
        const { withCooldown = false, key } = action.payload;
        let reactiveTime = null;
        const isUnpaidInvoice = notification.type === 'unpaid_invoice';
        const isTallySurvey = notification.type === 'tallySurvey';

        console.log({ isUnpaidInvoice, notification: notification.payload.type });

        if (isUnpaidInvoice) {
          if (notification.payload?.type === 'popup') {
            reactiveTime = Date.now();
          } else {
            reactiveTime = Date.now() + state.delayUnpaidInvoiceNotification;
          }
        } else if (isTallySurvey) {
          reactiveTime = withCooldown ? Date.now() + 2000 : null;
        } else {
          reactiveTime = withCooldown && notification.payload?.cooldown_time
            ? Date.now() + notification.payload.cooldown_time * 1000 : null;
        }

        console.log({ notifKey: notification.key, key, isSame: (notification.key === key), type: notification.payload?.type });

        if (notification.key === key) {
          console.log({
            ...notification,
            unpaidInvoiceeeee6: 'yadah',
            hasSeen: true,
            reactiveTime,
            payload: {
              ...notification.payload,
              type: notification.payload?.type === 'popup' ? 'banner' : 'popup',
            },
          });

          return {
            ...notification,
            hasSeen: true,
            reactiveTime,
            payload: {
              ...notification.payload,
              type: notification.payload?.type === 'popup' ? 'banner' : 'popup',
            },
          };
        }
        return notification;
      });
    },
    setCurrentGeneralNotification(state, action) {
      state.currentGeneralNotification = action.payload;
    },
    setActiveNotification(state, action) {
      state.generalSequence = state.generalSequence.map((notification) => {
        if (notification.key === action.payload) {
          return {
            ...notification,
            hasSeen: false,
          };
        }
        return notification;
      });
    },
    setDelayBetweenNotification(state, action) {
      state.delayBetweenNotification = (action.payload ?? 60) * 1000;
    },
    setDelayUnpaidInvoiceNotification(state, action) {
      state.delayUnpaidInvoiceNotification = (action.payload ?? 28800) * 1000;
    },
    evaluateNotificationByFlags: (state, action) => {
      const newNotifications = state
        .generalSequence
        .filter((notification) => (!notification.payload?.featureFlag
          ? true : action.payload.includes(notification.payload?.featureFlag)));
      state.generalSequence = newNotifications;
    },
  },
});

export const setInactiveNotification = createAsyncThunk(
  'notification/setInactiveNotification',
  // eslint-disable-next-line default-param-last
  async ({ withCooldown = false } = { withCooldown: false }, { dispatch, getState }) => {
    const { notification: { generalSequence } } = getState();
    const currentActiveNotification = generalSequence.map((a) => a)
      ?.sort((a, b) => b.priority - a.priority).filter((notification) => !notification.hasSeen)[0];

    dispatch(notificationSlice.actions
      .setHasSeenNotification({ key: currentActiveNotification.key, withCooldown }));

    dispatch(notificationSlice.actions.setStatus('inactivating'));
    setTimeout(() => {
      dispatch(notificationSlice.actions.setStatus('idle'));
    }, getState().notification.delayBetweenNotification);
  },
);

export const {
  setUnpaidInvoice,
  setUnpaidInvoiceType,
  queueNotification,
  setStatus,
  resetNotification,
  setActiveNotification,
  setDelayBetweenNotification,
  setDelayUnpaidInvoiceNotification,
  setVerifyEmailPopup,
  setVerifyEmailSent,
  evaluateNotificationByFlags,
  deleteUnpaidNotif,
} = notificationSlice.actions;

export const notificationState = (state) => state.notification;

export const currentActiveNotification = (state) => {
  const _state = Store.store.getState();
  if (Helpers.isBelajarIdEmail(_state?.user?.email)
    && nookies.get(null).coib !== 'close'
    && _state?.mainReducer?.currentPlans?.name?.toLowerCase()?.includes('free')) {
    return null;
  }

  const { generalSequence, status } = state.notification;
  const { redirectCheckout } = state.payment;

  if (redirectCheckout?.status === 'waiting') {
    return null;
  }
  if (generalSequence?.filter((notification) => !notification.hasSeen).length === 0) {
    return null;
  }
  if (status === 'inactivating') {
    return null;
  }
  return generalSequence.map((a) => a)?.sort((a, b) => b.priority
    - a.priority).filter((notification) => !notification.hasSeen)[0];
};

export const includePlanTypes = ['School', 'Education'];
export const asyncGetInitialUnpaidInvoiceNotifications = createAsyncThunk(
  'notification/asyncGetInitialUnpaidInvoiceNotification',
  async ({ uid = null }, { getState, dispatch }) => {
    try {
      let unpaidInvoices = await PaymentServices.getLastUnpaidOrder();

      if (unpaidInvoices && typeof unpaidInvoices === 'object' && !Array.isArray(unpaidInvoices)) {
        unpaidInvoices = [unpaidInvoices];
      }

      unpaidInvoices.forEach((unpaidInvoice) => {
        const isUnpaidExist = getState().notification.generalSequence
          .find((notification) => notification.key === unpaidInvoice.order_id);

        if (includePlanTypes.includes(unpaidInvoice?.pricing_plan?.plans_type)
          && (!isUnpaidExist || isUnpaidExist === undefined)) {
          dispatch(queueNotification({
            type: 'unpaid_invoice',
            key: unpaidInvoice.order_id,
            payload: {
              ...unpaidInvoice,
              type: 'popup',
              cooldown_time: getState().notification.delayUnpaidInvoiceNotification,
            },
            uid,
          }));
        }
      });
    } catch (error) {
      console.log(error);
    }
  },
);

export const asyncGetInitialRenewalInvoiceNotifications = createAsyncThunk(
  'notification/asyncGetInitialRenewalInvoiceNotifications',
  async (payload, { getState, dispatch }) => {
    try {
      const {
        active_plan: {
          payment_method: paymentMethod, expire_date: expireDate, ...rest
        },
      } = await PaymentServices.getActiveFeatures();
      const gracePeriod = getState().app?.listAppByKeyword?.grace_period_monthly?.value ?? 7;
      // const gracePeriod = -10000;
      const isNegative = gracePeriod < 0;

      const expireDateWithGracePeriod = {
        start: new Date(isNegative
          ? new Date(expireDate).setDate(new Date(expireDate).getDate() + gracePeriod)
          : new Date(expireDate)),
        end: new Date(isNegative
          ? new Date(expireDate)
          : new Date(expireDate).setDate(new Date(expireDate).getDate() + gracePeriod)),
      };

      const showRenewalNotification = new Date().getTime()
        > expireDateWithGracePeriod.start.getTime() && new Date().getTime()
        < expireDateWithGracePeriod.end.getTime();

      if ((paymentMethod === 'midtrans' || paymentMethod === 'onetime') && showRenewalNotification) {
        dispatch(queueNotification({
          type: 'renewal_invoice',
          key: 'renewal_invoice',
          payload: {
            ...rest,
            type: 'banner',
            payment_method: paymentMethod,
            expire_date: expireDate,
            cooldown_time: getState().notification.delayUnpaidInvoiceNotification,
          },
          uid: getState().auth.user.uid,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  },
);

const notificationReducer = notificationSlice.reducer;

export default notificationReducer;
