import { isBelajarIdUser } from 'assemblr-ui';

const Helpers = {
  uniqueObjectArray: (array, key) => array.reduce((acc, current) => {
    const x = acc.find((item) => item[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []),
  ucFirst: (string) => {
    const firstChar = string?.charAt?.(0);
    const remainingChars = string?.slice?.(1);
    return firstChar ? firstChar.toUpperCase() + (remainingChars ? remainingChars.toLowerCase() : '') : '';
  },
  resolveDuration: (duration) => {
    if (duration % 365 === 0) {
      return `${duration / 365} year${duration / 365 > 1 ? 's' : ''}`;
    }
    if (duration % 30 === 0) {
      return `${duration / 30} month${duration / 30 > 1 ? 's' : ''}`;
    }
    return `${duration} day${duration > 1 ? 's' : ''}`;
  },
  promoEventLog: ({
    promoType,
    promoAction,
    featureFlag,
    key,
    appConfigMode,
  }) => ({
    eventName: `${promoType}_${promoAction}`,
    eventParams: {
      promo_id: featureFlag,
      variant_id: key,
    },
    posthog: !appConfigMode,
  }),
  withLocales: (payload, locales = 'en-EN') => {
    const currentLanguange = locales.substring(3, 5);
    if (!payload?.locales || !payload?.locales[currentLanguange]) {
      return payload;
    } if (payload?.locales[currentLanguange]) {
      const localesPayload = payload?.locales[currentLanguange];
      return {
        ...payload,
        ...localesPayload,
      };
    }
    return payload;
  },
  isPathIncluded(pathname, type = 'notification') {
    if (!pathname) return false;
    if (type === 'notification') {
      const notificationIncludePaths = [
        '/Topic/*',
        '/Topics',
        '/Class',
        '/Projects',
        '/Projects/*',
        '/Setting',
        '/Search/Topic',
        '/topics/*',
        '/TeachingAids',
        '/TeachingAids/*',
        '/EduKits',
        '/EduKits/*',
      ].map((route) => route.toLowerCase());

      return notificationIncludePaths.some((route) => {
        const regex = new RegExp(`^${route.replace(/\*/g, '.*')}$`);
        return regex.test(pathname.toLowerCase());
      }) || pathname.toLowerCase() === '/';
    }
    return false;
  },
  isBelajarIdEmail(email) {
    return isBelajarIdUser({ email });
  },
  eduKitLimits(email) {
    // return isBelajarIdUser({ email }) ? 30 : 5;
    return email?.includes('belajar.id') ? 10 : 5;
  },
};

export default Helpers;
