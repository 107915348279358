/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import mainReducer from './Reducer';
import uiReducer from '../Store/reducers/uiReducer';
import onboardingReducer from '../Store/reducers/onboardingReducer';
import notificationReducer from '../Store/reducers/notificationReducer';
import { eduURL } from './env';
import posthogReducer from '../Store/reducers/posthogReducer';
import authReducer from '../Store/reducers/authReducer';
import paymentReducer from '../Store/reducers/paymentReducer';
import helperReducer from '../Store/reducers/helperReducer';
import appReducer from '../Store/reducers/appReducer';
import defaultMiddleware from '../Store/middlewares/defaultMiddleware';

export const encryptTransformConfig = encryptTransform({
  secretKey: process.env.NEXT_PUBLIC_STORAGE_KEY || 'LetsBuild',
  onError() {
    storage.keys().then((keys) => {
      keys.forEach((key) => {
        if (key.indexOf('persist:') === 0) {
          storage.removeItem(key);
        }
      });
      window.location.reload();
    });
  },
});

const transforms = process.env.NEXT_PUBLIC__DEBUG_MODE === 'TRUE' ? [] : [encryptTransformConfig];

const config = {
  key: 'primary',
  storage,
  transforms,
};

const notificationPersistConfig = {
  key: 'notification',
  storage,
  transforms,
};

// const paymentPersistConfig = {
//   key: 'payment',
//   storage,
//   transforms,
// };

const authPersistConfig = {
  key: 'auth',
  storage,
  transforms,
};

const appPersistConfig = {
  key: 'app',
  storage,
  transforms,
};

const helperPersistConfig = {
  key: 'helper',
  storage,
  transforms,
};

const paymentPersistConfig = {
  key: 'payment',
  storage,
  transforms,
};

const rootReducer = combineReducers({
  mainReducer: persistReducer(config, mainReducer),
  ui: uiReducer,
  onboarding: onboardingReducer,
  notification: persistReducer(notificationPersistConfig, notificationReducer),
  posthog: posthogReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  payment: persistReducer(paymentPersistConfig, paymentReducer),
  helper: persistReducer(helperPersistConfig, helperReducer),
  app: persistReducer(appPersistConfig, appReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddleware)
    .concat(typeof window !== 'undefined' ? createStateSyncMiddleware({
      broadcastChannelOption: {
        channel: 'state-sync',
      },
      whitelist: ['UPDATE_ACCOUNT', 'LOGOUT_USER', 'LOGIN_USER', 'UPDATE_PLAN', 'SET_META_DATA'],
    }) : [])
    .concat(thunk),
  devTools: !(!(eduURL).includes('pr-') && process.env.NODE_ENV === 'production'),
});

initMessageListener(store);

const persistor = persistStore(store);

export default { store, persistor };
