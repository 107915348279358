export const PAYMENT_URL = process.env.NODE_ENV === 'production'
  ? process.env.NEXT_PUBLIC_PAYMENT_URL : process.env.NEXT_PUBLIC_PAYMENT_URL_DEV;

// Analytics
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTIC_ID;
export const GOOGLE_MEAUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREID;
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
// export const MIXPANEL_TOKEN = 'b98374571d429918850cbbb4a98e232f';
export const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;

export const PLAYER_URL = process.env.NEXT_PUBLIC_PLAYER_URL || 'https://viewer-edu.assemblrworld.com';

export default {
  PAYMENT_URL,
};
