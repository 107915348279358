import Axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import fetchAdapter from '../services/fetchAdapter';

const baseURL = process.env.NODE_ENV === 'production'
? process.env.NEXT_PUBLIC_API_URL : process.env.NEXT_PUBLIC_API_URL_DEV;

// baseURL = "https://240a-2404-8000-1024-16c3-c53f-d3d5-544d-88d1.ngrok-free.app/api";
// baseURL = "https://staging.asblr.app/api";

const api = Axios.create({
    baseURL,
    timeout: 10000,
    adapter: fetchAdapter,
});

const apiWithToken = Axios.create({
    baseURL,
    timeout: 10000,
});

apiWithToken.interceptors.request.use(async (config) => {
    const currentUser = await getAuth().currentUser;
    const token = await getIdToken(currentUser);

    config.headers.Authorization = token;
    return config;
});

export {
    api, apiWithToken,
};

export default baseURL;
