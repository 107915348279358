import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/messaging';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const requestPermission = async (app) => {
    try {
        if ('Notification' in window) {
            const messaging = firebase.messaging(app);
            await Notification.requestPermission();
            const token = await messaging.getToken({
                vapidKey: process.env.NEXT_PUBLIC_WEB_PUSH_CERTIFICATES,
            });

            window.fcmToken = token;

            messaging.onMessage(() => {
                // TODO : NOTIFICATION UI
            });

            firebase.auth(app).onAuthStateChanged((user) => {
                if (user && window.fcmToken) {
                    firebase
                        .database()
                        .ref(`Education/WebUserMessagingTokens/${user.uid}`)
                        .set(window.fcmToken);
                }
            });

            return token;
        }
    } catch (error) {
        console.error(error);
    }
};

function initializeFirebase() {
    const firebaseConfig = {
        databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_ID,
        authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_KEY,
        storageBucket: process.env.NEXT_PUBLIC_STORAGEBUCK,
        measurementId: process.env.NEXT_PUBLIC_MEASUREID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
        messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    };
    try {
        if (typeof window === 'undefined') return;
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();

        // if (firebase.messaging.isSupported()) {
        //     requestPermission(app);
        // }
    } catch (error) {
        if (!/already exists/u.test(error.message)) {
            console.log('Firebase initialization error', error.stack);
        }
    }
}

export default initializeFirebase;
