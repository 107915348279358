import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    initialRoles: [
        {
            name: 'Teacher',
            icon: '/icon/notebook-dynamic-color.png',
            nextStep: {
                name: 'sub-role',
                multiple: true,
                subtitle: 'Which grade level do you teach?',
                banner: '/image/teacher_banner.png',
            },
            main: true,
        },
        {
            name: 'Student',
            icon: '/icon/pencil-dynamic-color.png',
            nextStep: {
                name: 'sub-role',
                multiple: false,
                subtitle: 'Which grade level are you currently in?',
                banner: '/image/student_banner.png',
            },
            main: true,
        },
        {
            name: 'Business Owner',
            icon: '/icon/chart-dynamic-color.png',
            nextStep: {
                name: 'sector',
                multiple: false,
                subtitle: 'Which industry does your business fall under?',
                banner: '/image/business_owner_banner.png',
            },
            main: true,
        },
        {
            name: 'Designer',
            icon: '/icon/picture-dynamic-color.png',
            nextStep: {
                name: 'sub-role',
                multiple: false,
                subtitle: 'Which type of designer are you?',
                banner: '/image/designer_banner.png',
            },
            main: true,
        },
        {
            name: 'Marketer',
            icon: '/icon/megaphone-dynamic-color.png',
            nextStep: {
                name: 'sector',
                multiple: false,
                subtitle: 'Which industry are you in specifically?',
                banner: '/image/onboarding_industry.png',

            },
            main: true,
        },
        {
            name: 'Other',
            icon: null,
            nextStep: {
                name: 'sector',
                multiple: false,
            },
            main: false,
            subtitle: 'Which industry are you in specifically?',
            banner: '/image/onboarding_industry.png',
        },
    ],
    role: null,
    subRole: null,
    sector: null,
};

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setSubRole: (state, action) => {
            state.subRole = action.payload;
        },
        setSector: (state, action) => {
            state.sector = action.payload;
        },
    },
});

export const onboadingState = (state) => state.onboarding;

export const mainRoleState = (state) => state.onboarding.initialRoles.filter((role) => role.main);

export const { setRole, setSubRole, setSector } = onboardingSlice.actions;

export default onboardingSlice.reducer;
