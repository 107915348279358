/* eslint-disable default-param-last */
const initialState = {
    isBoarding2: false,
    isBoarding: false,
    LanguageData: '',
    AccountData: {
        Username: '',
        FirebaseID: '',
        ProfilePictureURL: '',
        Name: '',
        Bio: '',
        Email: '',
    },
    currentPlans: {
        name: 'Free Plan',
        seats: 0,
        seats_limit: 0,
        custom_object: 0,
        custom_object_limit: 0,
        custom_graphic: 0,
        custom_graphic_limit: 0,
        invoice: '',
        expire_date: '',
        admin: '',
        subscription_id: '',
        status: 1,
        update_url: '',
        plan_type: '',
        nonedu: false,
    },
    currentPlanRawData: null,
    ActivePlansData: {},
    TopicData: [],
    ClassList: [],
    ClassDetail: [],
    isLoggedIn: false,
    loginType: '',
    BuildVersion: 3.3,
    AppsLanguage: '',
    CsvVersion: '',
    UnityVersion: '0',
    metaData: {},
    listAppByKeyword: null,
};

// Reducer
const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            console.log('reducer processing login');
            return {
                ...state,
                AccountData: action.data.userData,
                isLoggedIn: true,
                loginType: action.data.loginType,
                isBoarding2: false,
            };
        case 'LOGOUT_USER':
            // console.log('Logout Success User Data has been removed')
            localStorage.removeItem('onboard2');
            return {
                ...state,
                AccountData: {
                    Username: '',
                    FirebaseID: '',
                    ProfilePictureURL: '',
                    Name: '',
                    Bio: '',
                    Email: '',
                },
                currentPlans: {
                    name: 'Free Plan',
                    seats: 0,
                    seats_limit: 0,
                    custom_object: 0,
                    custom_object_limit: 0,
                    custom_graphic: 0,
                    custom_graphic_limit: 0,
                },
                ClassList: [],
                ActivePlansData: {},
                isLoggedIn: false,
                FirebaseToken: '',
                isBoarding2: false,
                metaData: {},
            };
        case 'UPDATE_ACCOUNT':
            return {
                ...state,
                AccountData: {
                    ...state.AccountData,
                    ...action.data,
                },

            };
        case 'UPDATE_TOPIC':
            return {
                ...state,
                TopicData: action.data,
            };
        case 'UPDATE_DETAIL_CLASS':
            // console.log('updating class detail at : '+window.location.pathname)
            return {
                ...state,
                ClassDetail: action.data,
            };
        case 'UPDATE_CLASS':
            return {
                ...state,
                ClassList: action.data,
            };

        case 'UPDATE_PLAN':
            return {
                ...state,
                currentPlans: {
                    ...state.currentPlans,
                    name: action.data.name,
                    seats: action.data.seats,
                    seats_limit: action.data.seats_limit,
                    custom_object: action.data.custom_object,
                    custom_object_limit: action.data.custom_object_limit,
                    custom_graphic: action.data.custom_graphic,
                    custom_graphic_limit: action.data.custom_graphic_limit,
                    invoice: action.data.invoice,
                    offlineFeature: action.data.offlineFeature,
                    expire_date: action.data.expire_date,
                    admin: action.data.admin,
                    subscription_id: action.data.subscription_id,
                    status: action.data.status,
                    update_url: action.data.update_url,
                    plan_type: action.data.plan_type,
                    nonedu: action.data.nonedu,
                    ...action.data,
                },
            };
        case 'BOARDED':
            return {
                ...state,
                isBoarding: true,
            };
        case 'BOARDED2':
            return {
                ...state,
                isBoarding2: true,
            };
        case 'UPDATE_VERSION':
            // console.log('reducer get new version : '+action.data)
            return {
                ...state,
                BuildVersion: action.data,
            };
        case 'UPDATE_LANGUAGE_DATA':
            // console.log(action.data.CSV_VERSION)
            // console.log('updating language data')
            return {
                ...state,
                CsvVersion: action.data.CSV_VERSION,
                LanguageData: action.data.LanguageData,

            };
        case 'UPDATE_UNITY_VERSION':
            console.log(`Unity Version Updated to :${action.data}`);
            return {
                ...state,
                UnityVersion: action.data,
            };
        case 'CHANGE_LANGUAGE':
            console.log(`language change to : ${action.data}`);
            return {
                ...state,
                AppsLanguage: action.data,
            };
        case 'UPDATE_RAW_PLAN_DATA':
            console.log(`language change to : ${action.data}`);
            return {
                ...state,
                currentPlanRawData: action.data,
            };
        case 'SET_META_DATA':
            return {
                ...state,
                metaData: { ...action.data },
            };
        case 'SET_APP_CONFIG':
            return {
                ...state,
                listAppByKeyword: action.data,
            };
        default:
        return state;
    }
};

export const mainState = (state) => state.mainReducer;

export const setLanguage = (data) => ({
    type: 'CHANGE_LANGUAGE',
    data,
});

export const setMetaData = (data) => ({
    type: 'SET_META_DATA',
    data,
});

export default mainReducer;
