import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PAYMENT_URL } from '../../Config/globals';

const initialState = {
  popupPaymentStatus: {
    status: 'idle',
    invoiceId: null,
    result: null,
    invoiceUrl: null,
  },
  redirectCheckout: {
    status: 'idle',
    params: {},
    type: 'checkout', // checkout & redeem
  },
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPopupPaymentStatus(state, action) {
      state.popupPaymentStatus = {
        ...state.popupPaymentStatus,
        ...action.payload,
      };
    },
    setRedirectCheckout(state, action) {
      state.redirectCheckout = {
        ...state.redirectCheckout,
        ...action.payload,
      };
    },
  },
});

export const setPopupPaymentStatus = createAsyncThunk(
  'payment/setPopupPaymentStatus',
  (data, thunkAPI) => {
    const { status, invoiceId, result, renewal } = data;
    const { dispatch, getState } = thunkAPI;
    const currencyCode = getState().app.currency;
    const token = getState().auth.user?.stsTokenManager?.accessToken;
    const params = new URLSearchParams({
      currency: currencyCode,
      isEdu: true,
      edu: true,
      token,
    });

    if (renewal) {
      params.append('renewal', renewal);
    }

    const invoiceUrl = `${PAYMENT_URL}/status/${invoiceId}?${params.toString()}`;
    dispatch(paymentSlice.actions.setPopupPaymentStatus({
      status,
      invoiceId,
      result,
      invoiceUrl,
    }));
  },
);

export const paymentState = (state) => state.payment;

const paymentReducer = paymentSlice.reducer;

export const {
  setRedirectCheckout,
} = paymentSlice.actions;

export default paymentReducer;
