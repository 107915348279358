export const LEFT = 'left';
export const RIGHT = 'right';
export const UP = 'up';
export const DOWN = 'down';

const initialState = {
    layoutTransitionDirection: UP,
    notifications: [],
    remoteConfigs: {},
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LAYOUT_TRANSITION_DIRECTION':
            return {
                ...state,
                layoutTransitionDirection: action.payload,
            };
        case 'ADD_NOTIFICATION':
            return {
                ...state,
                notifications: [...state.notifications, action.payload].reverse(),
            };
        case 'REMOVE_NOTIFICATION':
            return {
                ...state,
                notifications:
                state.notifications.filter((notification) => notification.id !== action.payload),
            };
        case 'SET_REMOTE_CONFIGS':
            return {
                ...state,
                remoteConfigs: action.payload,
            };
        default:
            return state;
    }
};

export const setLayoutTransitionDirection = (direction) => async (dispatch) => {
    try {
      dispatch({
        type: 'SET_LAYOUT_TRANSITION_DIRECTION',
        payload: direction,
      });
    } catch (error) {
      dispatch({
        type: 'SET_LAYOUT_TRANSITION_DIRECTION',
        payload: LEFT,
      });
    }
  };

  export const addNotification = (notification) => async (dispatch) => {
    try {
      dispatch({
        type: 'ADD_NOTIFICATION',
        payload: notification,
      });
    } catch (error) {
      // TODO : handle error
    }
  };

  export const removeNotification = (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'REMOVE_NOTIFICATION',
        payload: id,
      });
    } catch (error) {
      // TODO : handle error
    }
  };

  export const setRemoteConfigs = (configs) => async (dispatch) => {
    try {
      dispatch({
        type: 'SET_REMOTE_CONFIGS',
        payload: configs,
      });
    } catch (error) {
      // TODO : handle error
    }
  };

export default uiReducer;
