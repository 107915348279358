import { createSlice } from '@reduxjs/toolkit';
import userAgent from '../../Utils/userAgent';

const initialCommit = {
  historyFetchKeys: [],
  mainLoader: false,
  unityVersion: null,
  activeItems: {},
};

const mainLoaderMinVersion = '1.6.3';

const isMainLoaderMinVersion = (version) => {
  const versionArray = version.split('.');
  const minVersionArray = mainLoaderMinVersion.split('.');
  const calcVersion = (versionArray[0] * 100) + (versionArray[1] * 10) + (versionArray[2] * 1);
  const calcMinVersion = (minVersionArray[0] * 100)
  + (minVersionArray[1] * 10) + (minVersionArray[2] * 1);
  return calcVersion >= calcMinVersion;
};

const helperSlice = createSlice({
  name: 'helper',
  initialState: initialCommit,
  reducers: {
    setHistoryFetchKey: (state, action) => {
      state.historyFetchKeys.push(action.payload);
    },
    removeHistoryFetchKey: (state, action) => {
      state.historyFetchKeys = state.historyFetchKeys.filter((key) => key !== action.payload);
    },
    resetHistoryFetchKeys: (state) => {
      state.historyFetchKeys = [];
    },
    setMainLoader: (state, { payload }) => {
      if (!userAgent.isMobile.IOS() && isMainLoaderMinVersion(state.unityVersion || localStorage.getItem('appVersion') || '0.0.0')) {
        state.mainLoader = payload;
      }
    },
    setUnityVersion: (state, { payload }) => {
      if (payload && payload !== '0.0.0' && payload !== '0' && payload !== '') {
        state.unityVersion = payload;
        localStorage.setItem('appVersion', payload);
      }
    },
    setActiveItems: (state, { payload }) => {
      state.activeItems = {
        ...state.activeItems,
        ...payload,
      };
    },
  },
});

export const {
  setHistoryFetchKey,
  removeHistoryFetchKey,
  resetHistoryFetchKeys,
  setMainLoader,
  setUnityVersion,
  setActiveItems,
} = helperSlice.actions;

export const helperState = (state) => state.helper;
export const activeItemState = (state) => (key) => state.helper.activeItems[key];

const helperReducer = helperSlice.reducer;

export default helperReducer;
